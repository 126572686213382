import React from 'react';

const LoadingSpinner = ({ testMode }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100/80 dark:bg-gray-900/80 backdrop-blur-sm">
      <div className="relative">
        <div className="w-12 h-12 rounded-full border-4 border-purple-600 border-t-transparent animate-spin"></div>
      </div>
      {testMode && (
        <div className="absolute bottom-10 text-gray-600 dark:text-gray-300">
          Test Mode: Loading for 5 seconds...
        </div>
      )}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
