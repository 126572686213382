import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ErrorBoundary from './components/common/ErrorBoundary';
import LoadingSpinner from './components/common/LoadingSpinner';
import reportWebVitals from './reportWebVitals';

// Lazy load main App component
const App = React.lazy(() => import('./App'));

const AppWrapper = () => {
  const [showTestLoading, setShowTestLoading] = useState(false);

  useEffect(() => {
    const testMode = localStorage.getItem('iosPromptTestMode') === 'true';
    if (testMode) {
      setShowTestLoading(true);
      setTimeout(() => {
        setShowTestLoading(false);
        localStorage.setItem('iosPromptTestMode', 'false');
      }, 5000);
    }
  }, []);

  if (showTestLoading) {
    return <LoadingSpinner testMode={true} />;
  }

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Suspense fallback={<LoadingSpinner testMode={false} />}>
          <App />
        </Suspense>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWrapper />);

// Register service worker
serviceWorkerRegistration.register();

// Report web vitals
reportWebVitals(metric => {
  if (process.env.NODE_ENV === 'production') {
    // Send to analytics
    console.log(metric);
  }
});
